import React, {useState} from 'react';

import {Visibility, VisibilityOff} from '@mui/icons-material';
import Button from "components/button/Button";
import {Close} from "components/icons";
import RenderIf from 'components/RenderIf';
import {useFormik} from 'formik';
import {changePassword, checkOtp} from 'modules/auth/_redux/authCrud';
import {actions} from 'modules/auth/_redux/authRedux';
import {Modal} from 'react-bootstrap';
import {createUseStyles} from 'react-jss'
import {useDispatch, useSelector} from 'react-redux';
import {toast} from "react-toastify";
import * as Yup from 'yup';

import useOtpTimer from "../../hooks/useOtpTimer";
import PinField from "../../modules/auth/components/PinField";
import Input from "../input/Input";


const useStyles = createUseStyles({
    title: {
        fontSize: "48px",
        textAlign: "center",
        lineHeight: "58px",
        fontWeight: 500
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        justifyContent: 'space-between',
    },
    input: {
        '&:focus': {
            border: '1px solid #ff0009',
        },
        border: '1px solid #999999',
        borderRadius: '8px',
        outline: 'unset',
        padding: ' 5px 16px',
        height: '40px',
    },
    pinInput: {
        width: '50px',
        height: '50px',
        '&:focus': {
            border: '1px solid #ff0009',
        },
        border: '1px solid #999999',
        borderRadius: '8px',
        outline: 'unset',
        padding: ' 5px 16px',
        textAlign: 'center',
    },
    label: {
        marginLeft: '20px',
    },
    closeButton: {
        width: "36px",
        height: "36px",
        border: "unset",
        borderRadius: "50%",
        position: "absolute",
        right: "30px",
        top: "20px",
        background: "#1E1E1E",

        "& svg": {
            stroke: "#FFFFFF"
        }
    },
    info: {
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "28px",
        textAlign: "center"
    }
});

export default function ChangePasswordModal({show, onHide, errorStatus,}) {
    const [currentForm, setCurrentForm] = useState(1);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordTwo, setShowPasswordTwo] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const togglePasswordVisibilityTwo = () => {
        setShowPasswordTwo(!showPasswordTwo);
    };
    const classes = useStyles();
    const [error, setError] = useState('');

    const dispatch = useDispatch();

    const {phoneNumber} = useSelector(state => state.auth);

    const {time, isFinished, isDisabled, resendOtp} = useOtpTimer(120);

    const {login} = actions;

    const firstForm = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: Yup.object({
            otp: Yup.string().min(6).max(6).required('OTP kod tələb olunur'),
        }),
        onSubmit: (values) => {
            checkOtp({phoneNumber, otp: values.otp})
                .then(() => setCurrentForm(2))
                .catch((err) => {
                    const message = err.response?.data?.message ?? 'Xəta baş verdi';
                    setError(message);
                })
                .finally(() => firstForm.setSubmitting(false));
        },
    });

    const secondForm = useFormik({
        initialValues: {
            password: "",
            passwordCheck: ""
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required('Yeni şifrəni daxil edin')
                .min(8, 'Ən az 8 xarakter olmalıdır')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_-])(?=.{8,})/,
                    'Şifrə böyük hərf, kiçik hərf, rəqəm və xarakterdən(!@#$%^&*()_-) ibarət olmalıdır.'
                ),
            passwordCheck: Yup.string()
                .oneOf(
                    [Yup.ref('password'), null],
                    'Yeni şifrəni təkrar düzgün daxil edin'
                ),
        }),
        onSubmit: (values) => {
            changePassword(firstForm.values.otp, phoneNumber, values.password)
                .then(({data: {token, refreshToken, expireDate}}) => {
                    dispatch(login(token, refreshToken, expireDate));
                    toast.success("Şifrəniz uğurla yeniləndi");
                    onHide(false);
                })
                .catch((err) => {
                    const message = err.response?.data?.message ?? 'Xəta baş verdi';
                    setError(message);
                })
                .finally(() => secondForm.setSubmitting(false));
        }
    })

    function handleClose() {
        onHide(false);
    }

    return (
        <React.Fragment>
            <Modal centered show={show} onHide={onHide}>
                <RenderIf condition={errorStatus}>
                    <h1 style={{padding: '50px'}} className="text-center text-danger">
                        {errorStatus}
                    </h1>
                </RenderIf>
                <RenderIf condition={!errorStatus}>
                    {currentForm === 1 ? (
                        <form onSubmit={firstForm.handleSubmit}>
                            <Modal.Body>
                                <button type="button" onClick={handleClose} className={classes.closeButton}><Close/></button>
                                <h1 className={classes.title}>OTP verification</h1>
                                <div className={classes.inputContainer}>
                                    <p className="text-center mb-3">
                                        <span className="auth__blue-text">{phoneNumber}</span> nömrəsinə otp kod
                                        göndərildi
                                    </p>
                                    <p className="auth__otp-text mb-4">
                                        {isFinished ? (
                                            <>
                                                OTP kod gəlməyib? <button type="button"
                                                                          disabled={isDisabled || time !== 0}
                                                                          onClick={resendOtp}
                                                                          className="auth__otp-text-underline">Yenidən
                                                göndər</button>
                                            </>
                                        ) : time}
                                    </p>
                                    <PinField digit={6} onChange={firstForm.setFieldValue} field="otp"/>
                                </div>
                                <Button
                                    disabled={firstForm.isSubmitting}
                                    type="submit"
                                    className="auth__submit w-100 mt-4"
                                >
                                    Davam et
                                </Button>
                                <RenderIf condition={Boolean(error)}>
                                    <div className="d-flex justify-content-center mt-2">
                                        <span className="text-danger">{error}</span>
                                    </div>
                                </RenderIf>
                            </Modal.Body>
                        </form>
                    ) : (
                        <form onSubmit={secondForm.handleSubmit} style={{maxWidth: "650px"}}>
                            <Modal.Body>
                                <button type="button" onClick={handleClose} className={classes.closeButton}><Close/></button>
                                <h1 className={classes.title}>Yeni şifrə</h1>
                                <p className={classes.info}>Pin böyük hərf, kiçik hərf, rəqəm və
                                    xarakterdən(!@#$%^&*()_-) ibarət olmalıdır.</p>
                                <label className="mb-2">Şifrə</label>
                                <div className="mb-4 showButton">
                                    <Input
                                        placeholder="Şifrəni daxil edin"
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={secondForm.values.password}
                                        onChange={secondForm.handleChange}
                                        className="w-100"
                                    />
                                    <div className="showButton-eye">
                                        <span className={`password-toggle-icon ${showPassword ? 'visible' : 'hidden'}`}>
                                            <RenderIf condition={showPassword}>
                                                <VisibilityOff role="button" onClick={togglePasswordVisibility}/>
                                            </RenderIf>
                                            <RenderIf condition={!showPassword}>
                                                <Visibility role="button" onClick={togglePasswordVisibility}/>
                                            </RenderIf>
                                        </span>
                                    </div>
                                    {secondForm.touched.password && secondForm.errors.password ? (
                                        <div className="fv-plugins-message-container mt-2 ">
                                            <div className="fv-help-block text-danger text-center">
                                                {secondForm.errors.password}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>

                                <label className="mb-2 mt-2">Şifrə təkrar</label>
                                <div className="showButton">
                                    <Input
                                        placeholder="Təkrar şifrəni daxil edin"
                                        name="passwordCheck"
                                        type={showPasswordTwo ? 'text' : 'password'}
                                        value={secondForm.values.passwordCheck}
                                        onChange={secondForm.handleChange}
                                        className="w-100"
                                    />
                                    <div className="showButton-eye">
                                        <span
                                            className={`password-toggle-icon ${showPasswordTwo ? 'visible' : 'hidden'}`}>
                                            <RenderIf condition={showPasswordTwo}>
                                                <VisibilityOff role="button" onClick={togglePasswordVisibilityTwo}/>
                                            </RenderIf>
                                            <RenderIf condition={!showPasswordTwo}>
                                                <Visibility role="button" onClick={togglePasswordVisibilityTwo}/>
                                            </RenderIf>
                                        </span>
                                    </div>
                                    {secondForm.touched.passwordCheck && secondForm.errors.passwordCheck ? (
                                        <p className="error-text  text-danger text-center mt-2 ">
                                            {secondForm.errors.passwordCheck}
                                        </p>
                                    ) : null}
                                </div>
                                <Button
                                    disabled={secondForm.isSubmitting}
                                    type="submit"
                                    className="auth__submit w-100 mt-4"
                                >
                                    Təsdiqlə
                                </Button>
                            </Modal.Body>
                        </form>
                    )}
                </RenderIf>
            </Modal>
        </React.Fragment>
    );
}
