import {TextField, InputLabel} from "@mui/material";
import {useFormik} from "formik";
import {actions} from "modules/auth/_redux/authRedux";
import {Button} from "react-bootstrap";
import {createUseStyles} from "react-jss";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";

const useStyles = createUseStyles({
    container: {
        background: "#000000",
        with: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        maxWidth: "500px",
        width: "100%"

    },
    input: {
        background: "#FFFFFF"
    },
    label: {
        color: "rgb(152, 152, 152)"
    }
})

const TemporaryLoginPage = () => {
    const dispatch = useDispatch();
    
    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            input: ""
        },
        onSubmit: ({input}) => {
            if(input === 'Nard123!') {
                dispatch(actions.temporaryLogin(true))
            } else {
                toast.error("Şifrəni düzgün daxil edin.")
            }
        }
    })

    return (
        <div className={classes.container}>
            <form onSubmit={formik.handleSubmit} className={classes.form}>
                <InputLabel className={classes.label}>Access code</InputLabel>
                <TextField type="password" className={classes.input} {...formik.getFieldProps("input")}/>
                <Button type="submit">Log in</Button>
            </form>
        </div>
    )
}

export default TemporaryLoginPage