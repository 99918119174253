import React from 'react';

import axios from "axios";
import Button from "components/button/Button";
import {Close} from "components/icons";
import {useFormik} from "formik";
import {actions} from "modules/auth/_redux/authRedux";
import {Modal} from 'react-bootstrap';
import {createUseStyles} from 'react-jss';
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";


const useStyles = createUseStyles({
    title: {
        fontSize: "32px",
        textAlign: "center",
        lineHeight: "42px",
        fontWeight: 500
    },
    closeButton: {
        width: "36px",
        height: "36px",
        border: "unset",
        borderRadius: "50%",
        position: "absolute",
        right: "30px",
        top: "20px",
        background: "#1E1E1E",

        "& svg": {
            stroke: "#FFFFFF"
        }
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        marginBottom: "34px"
    },
    text: {
        maxWidth: "474px",
        fontSize: "16px",
        lineHeight: "24px",
        textAlign: "center"
    },
    inputContainer: {
        padding: "12px 16px",
        borderRadius: "10px",
        background: "#F5F5F5",
        "& svg": {
            marginRight: "8px"
        }
    },
    input: {
        border: "unset",
        background: "unset",
        outline: "unset"
    },
    form: {
        display: "flex",
        gap: "12px"
    },
    button: {
        background: "#00AFF5",
        width: "100%"
    }
});

export default function WithdrawModal({show, onHide, setShowPrintModal, setPrintData}) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            amount: ""
        },
        onSubmit: async () => {
            try {
                formik.setSubmitting(true);
                const {data} = await axios.post('winning/create', {});
                dispatch(actions.getProfile());
                setPrintData(data);
                setShowPrintModal(true);
                onHide(false);
            } catch (err) {
                toast.error(err.response?.data?.message ?? "Xəta baş verdi.")
            } finally {
                formik.setSubmitting(false);
            }
        }
    })

    function handleClose() {
        onHide(false);
    }

    return (
        <React.Fragment>
            <Modal centered show={show} onHide={onHide}>
                <Modal.Body>
                    <button type="button" onClick={handleClose} className={classes.closeButton}><Close/></button>
                    <div className={classes.container}>
                        <p className={classes.title}>Pulu çıxartmaq</p>
                        <p className={classes.text}>Çap olunan ticketi kassirdə scan edib pulu ala bilərsiniz.
                        </p>
                    </div>
                    <form onSubmit={formik.handleSubmit} className={classes.form}>
                        <Button disabled={formik.isSubmitting} className={classes.button} type="submit">Təsdiq et</Button>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}