import {useEffect} from 'react';

import RenderIf from "components/RenderIf";
import {actions} from 'modules/auth/_redux/authRedux';
import ResultPage from 'pages/ResultPage';
import {useDispatch, useSelector} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import PrivateRoutes from './PrivateRoutes';
import {useAuth} from '../hooks';
import UpdateBirthDate from "../modules/auth/components/UpdateBirthDate";
import Logout from '../modules/auth/pages/Logout';
import AuthPage from '../pages/AuthPage';
import BronPage from '../pages/BronPage';
import CirculationsPage from '../pages/CirculationsPage';
import DocumentsPage from "../pages/DocumentsPage";
import HomePage from '../pages/HomePage';
import PartnersPage from "../pages/PartnersPage";
import SportsmenPage from "../pages/SportsmenPage";
import TemporaryLoginPage from "../pages/TemporaryLoginPage";

export function Router() {
    const isAuth = useAuth();

    const dispatch = useDispatch();

    const user = useSelector(state => state.auth);

    useEffect(() => {
        if (isAuth) dispatch(actions.getProfile());
    }, [dispatch, isAuth]);

    return (
        <>
            <Routes>
                {user.temporaryLogin ? (
                    <>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/results" element={<ResultPage/>}/>
                        <Route path="/sportsmen" element={<SportsmenPage/>}/>
                        <Route path="/partners" element={<PartnersPage/>}/>
                        <Route path="/documents" element={<DocumentsPage/>}/>
                        <Route path="/bron" element={<BronPage/>}/>
                        <Route path="/circulations/*" element={<CirculationsPage/>}/>
                        <Route path="/logout/*" element={<Logout/>}/>
                        {isAuth ? (
                            <Route path="/*" element={<PrivateRoutes/>}/>
                        ) : (
                            <Route path="/auth/*" element={<AuthPage/>}/>
                        )}
                        <Route path="*" element={<Navigate to="/" replace/>}/>
                        <Route path="/login" element={<Navigate to="/" replace/>}/>
                    </>
                ): (
                    <>
                        <Route path="/login" element={<TemporaryLoginPage/>}/>
                        <Route path="*" element={<Navigate to="/login" replace/>}/>
                    </>
                )}
            </Routes>
            <ToastContainer style={{zIndex: "1000000"}}/>
            <RenderIf condition={user.id && typeof user.birthDate !== "undefined" && !user.birthDate}>
                <UpdateBirthDate/>
            </RenderIf>
        </>
    );
}
