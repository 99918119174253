import React, {useRef} from 'react';

import {Close} from "components/icons";
import Barcode from "react-barcode";
import {Modal} from 'react-bootstrap';
import { FaPrint } from "react-icons/fa6";
import {createUseStyles} from 'react-jss';
import {useReactToPrint} from "react-to-print";


const useStyles = createUseStyles({
    title: {
        fontSize: "32px",
        textAlign: "center",
        lineHeight: "42px",
        fontWeight: 500
    },
    closeButton: {
        width: "36px",
        height: "36px",
        border: "unset",
        borderRadius: "50%",
        position: "absolute",
        right: "30px",
        top: "20px",
        background: "#1E1E1E",

        "& svg": {
            stroke: "#FFFFFF"
        }
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
        marginBottom: "34px"
    },
    text: {
        maxWidth: "474px",
        fontSize: "16px",
        lineHeight: "24px",
        textAlign: "center"
    },
    inputContainer: {
        padding: "12px 16px",
        borderRadius: "10px",
        background: "#F5F5F5",
        "& svg": {
            marginRight: "8px"
        }
    },
    input: {
        border: "unset",
        background: "unset",
        outline: "unset"
    },
    form: {
        display: "flex",
        gap: "12px"
    },
    button: {
        background: "unset",
        "& svg": {
            width: "25px",
            height: "25px",
        }
    },
    modalBody: {
        padding: "60px 50px !important"
    }
});

export default function PrintModal({show, onHide, printData}) {
    const classes = useStyles();
    const ref = useRef();

    const handlePrint = useReactToPrint({
        content: () => ref.current,
        onAfterPrint: () => onHide(false)
    })


    function handleClose() {
        onHide(false);
    }

    return (
        <React.Fragment>
            <Modal centered show={show} onHide={onHide}>
                <Modal.Body className={classes.modalBody}>
                    <button type="button" onClick={handleClose} className={classes.closeButton}><Close/></button>
                    <div ref={ref} className={classes.container}>
                        <Barcode value={printData?.voucherNumber}/>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        Çap et<button onClick={handlePrint} className={classes.button}><FaPrint/></button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}