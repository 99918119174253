import { Fragment, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import Input from 'components/input/Input';
import RenderIf from 'components/RenderIf';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../../components/button/Button';
import { changePassword } from '../_redux/authCrud';
import CustomProgress from '../components/CustomProgress';

export default function CheckRegister() {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const togglePasswordVisibilityTwo = () => {
    setShowPasswordTwo(!showPasswordTwo);
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const { phoneNumber, otp } = useSelector(({ auth }) => ({
    phoneNumber: auth.phoneNumber?.replace(/[()+\s-]/g, ''),
    otp: auth.otp
  }));

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordCheck: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Şifrəni daxil edin')
        .min(8, 'Ən az 8 xarakter olmalıdır')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_-])(?=.{8,})/,
          'Şifrə böyük hərf, kiçik hərf, rəqəm və xarakterdən(!@#$%^&*()_-) ibarət olmalıdır.'
        ),
      passwordCheck: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Şifrəni təkrar düzgün daxil edin'
      ),
    }),
    onSubmit: (values) => {
      enableLoading();
      changePassword(otp, phoneNumber, values.password)
        .then(() => {
          disableLoading();
          navigate('/auth/login');
        })
        .catch(() => {
          disableLoading();
        });
    },
  });

  return (
    <Fragment>
      <Helmet>
        <title>Pin-i yenilə</title>
      </Helmet>
      <form onSubmit={formik.handleSubmit} className="auth__check-register">
        <Link to="/" className="auth__logo">
          <img src="/media/topaz-nard-logo-black.svg" alt="Topaz" />
        </Link>
        <div className="auth__title">
          <span>Yeni şifrə</span>
        </div>
        <p className="mb-5 lh-sm">Pin böyük hərf, kiçik hərf, rəqəm və xarakterdən(!@#$%^&*()_-) ibarət olmalıdır.</p>
        <label className="mb-2">Şifrə</label>
        <div className="showButton mb-2">
          <Input
            placeholder="Yeni şifrə(təkrar)"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            className="w-100"
          />
          <div className="showButton-eye">
            <span
              className={`password-toggle-icon ${
                showPassword ? 'visible' : 'hidden'
              }`}
            >
              <RenderIf condition={showPassword}>
                <VisibilityOff
                  role="button"
                  onClick={togglePasswordVisibility}
                />
              </RenderIf>
              <RenderIf condition={!showPassword}>
                <Visibility role="button" onClick={togglePasswordVisibility} />
              </RenderIf>
            </span>
          </div>
        </div>
        {formik.touched.password && formik.errors.password ? (
          <div className="fv-plugins-message-container my-2">
            <div className="fv-help-block text-danger text-center">
              {formik.errors.password}
            </div>
          </div>
        ) : null}

        <label className="my-2">Şifrə</label>
        <div className="showButton">
          <Input
            placeholder="Yeni şifrə(təkrar)"
            name="passwordCheck"
            type={showPasswordTwo ? 'text' : 'password'}
            value={formik.values.passwordCheck}
            onChange={formik.handleChange}
            className="w-100"
          />
          <div className="showButton-eye">
            <span
              className={`password-toggle-icon ${
                showPasswordTwo ? 'visible' : 'hidden'
              }`}
            >
              <RenderIf condition={showPasswordTwo}>
                <VisibilityOff
                  role="button"
                  onClick={togglePasswordVisibilityTwo}
                />
              </RenderIf>
              <RenderIf condition={!showPasswordTwo}>
                <Visibility
                  role="button"
                  onClick={togglePasswordVisibilityTwo}
                />
              </RenderIf>
            </span>
          </div>
        </div>
        {formik.touched.passwordCheck && formik.errors.passwordCheck ? (
          <div className="fv-plugins-message-container my-2">
            <div className="fv-help-block text-danger text-center">
              {formik.errors.passwordCheck}
            </div>
          </div>
        ) : null}

        <Button type="submit" className="auth__submit mt-4">
          Təsdiqlə
        </Button>

        {loading && (
          <div className="custom-progress">
            <CustomProgress />
          </div>
        )}
      </form>
    </Fragment>
  );
}
