import { useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import Button from 'components/button/Button';
import EyeInput from 'components/EyeInput';
import RenderIf from 'components/RenderIf';
import { useFormik } from 'formik';
import { connect, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {toast} from "react-toastify";
import * as Yup from 'yup';

import { checkRegister } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';
import CustomProgress from '../components/CustomProgress';

function CheckRegister(props) {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const togglePasswordVisibilityTwo = () => {
    setShowPasswordTwo(!showPasswordTwo);
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const { phoneNumber, otp } = useSelector(({ auth }) => ({
    phoneNumber: auth.phoneNumber,
    otp: auth.otp
  }));

  const formik = useFormik({
    initialValues: {
      otp: '',
      password: '',
      passwordCheck: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Şifrəni daxil edin')
        .min(8, 'Ən az 8 xarakter olmalıdır')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_-])(?=.{8,})/,
          'Şifrə böyük hərf, kiçik hərf, rəqəm və xarakterdən(!@#$%^&*()_-) ibarət olmalıdır.'
        ),
      passwordCheck: Yup.string().when('pin', {
        is: (val) => val && val.length > 0,
        then: Yup.string().oneOf(
          [Yup.ref('pin')],
          'Hər iki şifrə eyni olmalıdır.'
        ),
      }),
    }),
    onSubmit: (values) => {
      enableLoading();
      checkRegister({
        phoneNumber,
        password: values.password,
        otp
      })
        .then(({ data: { token } }) => {
          disableLoading();
          props.register(token);
          navigate('/');
        })
        .catch((err) => {
          toast.error(err.response?.data?.message ?? 'Xəta baş verdi');
          disableLoading();
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="auth__check-register">
      <Link to="/" className="auth__logo">
        <img src="/media/topaz-nard-logo-black.svg" alt="Topaz" />
      </Link>
      <div className="auth__title">
        <span className="text-nowrap">Şifrənin təyin olunması</span>
      </div>
      <p className="mb-4">Pin böyük hərf, kiçik hərf, rəqəm və xarakterdən(!@#$%^&*()_-) ibarət olmalıdır.</p>
      <label className="mb-2">Şifrə</label>
      <div className="mb-4 showButton">
        <EyeInput
            placeholder="Şifrəni daxil edin"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            className="w-100"
        />
        <div className="showButton-eye">
          <span
            className={`password-toggle-icon ${
              showPassword ? 'visible' : 'hidden'
            }`}
          >
            <RenderIf condition={showPassword}>
              <VisibilityOff role="button" onClick={togglePasswordVisibility} />
            </RenderIf>
            <RenderIf condition={!showPassword}>
              <Visibility role="button" onClick={togglePasswordVisibility} />
            </RenderIf>
          </span>
        </div>
        {formik.touched.pin && formik.errors.pin ? (
          <div className="fv-plugins-message-container mt-2 ">
            <div className="fv-help-block text-danger text-center">
              {formik.errors.pin}
            </div>
          </div>
        ) : null}
      </div>

      <label className="mb-2 mt-2">Şifrə təkrar</label>
      <div className="showButton">
        <EyeInput
          placeholder="Təkrar şifrəni daxil edin"
          name="passwordCheck"
          type={showPasswordTwo ? 'text' : 'password'}
          value={formik.values.passwordCheck}
          onChange={formik.handleChange}
          className="w-100"
        />
        <div className="showButton-eye">
          <span
            className={`password-toggle-icon ${
              showPasswordTwo ? 'visible' : 'hidden'
            }`}
          >
            <RenderIf condition={showPasswordTwo}>
              <VisibilityOff
                role="button"
                onClick={togglePasswordVisibilityTwo}
              />
            </RenderIf>
            <RenderIf condition={!showPasswordTwo}>
              <Visibility role="button" onClick={togglePasswordVisibilityTwo} />
            </RenderIf>
          </span>
        </div>
        {formik.touched.pinCheck && formik.errors.pinCheck ? (
          <p className="error-text  text-danger text-center mt-2 ">
            {formik.errors.pinCheck}
          </p>
        ) : null}
      </div>
      <Button type="submit" className="auth__submit mt-4">
        Təsdiqlə
      </Button>
      {loading && (
        <div className="custom-progress">
          <CustomProgress />
        </div>
      )}
    </form>
  );
}

export default connect(null, auth.actions)(CheckRegister);
